import React from 'react';
import OCRComponent from './OCRComponent';

const DocumentUploadPage = () => {
  return (
    <div>
      <h1>Upload & Scan</h1>
      <OCRComponent />
    </div>
  );
};

export default DocumentUploadPage;
