// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ocr-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
}

.file-input {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.progress-bar {
  height: 5px;
  background-color: #4CAF50;
  margin-bottom: 20px;
  width: 0%; /* Initial width */
  transition: width 0.5s ease-in-out;
}

.ocr-result {
  width: 40%;
  height: 600px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  font-family: monospace;
}
.ocr-file-upload {
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/OCRComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,uBAAuB;EACvB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,SAAS,EAAE,kBAAkB;EAC7B,kCAAkC;AACpC;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".ocr-container {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  margin-top: 20px;\n  justify-content: center;\n  flex-wrap: wrap;\n  align-content: flex-start;\n}\n\n.file-input {\n  margin-bottom: 20px;\n  border: 1px solid #ccc;\n  display: inline-block;\n  padding: 6px 12px;\n  cursor: pointer;\n}\n\n.progress-bar {\n  height: 5px;\n  background-color: #4CAF50;\n  margin-bottom: 20px;\n  width: 0%; /* Initial width */\n  transition: width 0.5s ease-in-out;\n}\n\n.ocr-result {\n  width: 40%;\n  height: 600px;\n  margin-bottom: 20px;\n  border: 1px solid #ddd;\n  font-family: monospace;\n}\n.ocr-file-upload {\n  width: 100%;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
